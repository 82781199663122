export const pageTheme: any = {
  '--wix-color-1': `255, 255, 255`,
  '--wix-color-2': `207, 204, 204`,
  '--wix-color-3': `150, 150, 150`,
  '--wix-color-4': `100, 100, 100`,
  '--wix-color-5': `0, 0, 0`,
  '--wix-color-6': `207, 229, 255`,
  '--wix-color-7': `140, 186, 255`,
  '--wix-color-8': `21, 102, 224`,
  '--wix-color-9': `0, 70, 156`,
  '--wix-color-10': `35, 50, 123`,
  '--wix-color-11': `249, 197, 180`,
  '--wix-color-12': `243, 167, 143`,
  '--wix-color-13': `237, 88, 41`,
  '--wix-color-14': `158, 59, 27`,
  '--wix-color-15': `79, 29, 14`,
  '--wix-color-16': `210, 172, 247`,
  '--wix-color-17': `186, 131, 240`,
  '--wix-color-18': `128, 21, 232`,
  '--wix-color-19': `85, 14, 155`,
  '--wix-color-20': `43, 7, 77`,
  '--wix-color-21': `177, 211, 187`,
  '--wix-color-22': `127, 168, 139`,
  '--wix-color-23': `64, 124, 81`,
  '--wix-color-24': `43, 83, 54`,
  '--wix-color-25': `21, 41, 27`,
  '--wix-color-37': `0, 0, 0`, // not from checkout, this effects the default border color of textField
  '--wix-font-Title': `normal normal normal 48px/1.5em madefor-text-mediumbold,
  helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
  sans-serif`,
  '--wix-font-Title-style': `normal`,
  '--wix-font-Title-variant': `normal`,
  '--wix-font-Title-weight': `normal`,
  '--wix-font-Title-size': `48px`,
  '--wix-font-Title-line-height': `1.5em`,
  '--wix-font-Title-family': `madefor-text-mediumbold, helveticaneuew01-45ligh,
  helveticaneuew02-45ligh, helveticaneuew10-45ligh, sans-serif`,
  '--wix-font-Title-text-decoration': `none`,
  '--wix-font-Menu': `normal normal normal 16px/1.5em din-next-w01-light,
  din-next-w02-light, din-next-w10-light, sans-serif`,
  '--wix-font-Menu-style': `normal`,
  '--wix-font-Menu-variant': `normal`,
  '--wix-font-Menu-weight': `normal`,
  '--wix-font-Menu-size': `16px`,
  '--wix-font-Menu-line-height': `1.5em`,
  '--wix-font-Menu-family': `din-next-w01-light, din-next-w02-light,
  din-next-w10-light, sans-serif`,
  '--wix-font-Menu-text-decoration': `none`,
  '--wix-font-Page-title': `normal normal normal 32px/1.5em madefor-text-mediumbold,
  helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
  sans-serif`,
  '--wix-font-Page-title-style': `normal`,
  '--wix-font-Page-title-variant': `normal`,
  '--wix-font-Page-title-weight': `normal`,
  '--wix-font-Page-title-size': `32px`,
  '--wix-font-Page-title-line-height': `1.5em`,
  '--wix-font-Page-title-family': `madefor-text-mediumbold, helveticaneuew01-45ligh,
  helveticaneuew02-45ligh, helveticaneuew10-45ligh, sans-serif`,
  '--wix-font-Page-title-text-decoration': `none`,
  '--wix-font-Heading-XL': `normal normal normal 28px/1.5em madefor-text-mediumbold,
  helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
  sans-serif`,
  '--wix-font-Heading-XL-style': `normal`,
  '--wix-font-Heading-XL-variant': `normal`,
  '--wix-font-Heading-XL-weight': `normal`,
  '--wix-font-Heading-XL-size': `28px`,
  '--wix-font-Heading-XL-line-height': `1.5em`,
  '--wix-font-Heading-XL-family': `madefor-text-mediumbold, helveticaneuew01-45ligh,
  helveticaneuew02-45ligh, helveticaneuew10-45ligh, sans-serif`,
  '--wix-font-Heading-XL-text-decoration': `none`,
  '--wix-font-Heading-L': `normal normal normal 24px/1.5em madefor-text-mediumbold,
  helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
  sans-serif`,
  '--wix-font-Heading-L-style': `normal`,
  '--wix-font-Heading-L-variant': `normal`,
  '--wix-font-Heading-L-weight': `normal`,
  '--wix-font-Heading-L-size': `24px`,
  '--wix-font-Heading-L-line-height': `1.5em`,
  '--wix-font-Heading-L-family': `madefor-text-mediumbold, helveticaneuew01-45ligh,
  helveticaneuew02-45ligh, helveticaneuew10-45ligh, sans-serif`,
  '--wix-font-Heading-L-text-decoration': `none`,
  '--wix-font-Heading-M': `normal normal normal 20px/1.5em madefor-text-mediumbold,
  helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
  sans-serif, Noto Sans Hebrew`,
  '--wix-font-Heading-M-style': `normal`,
  '--wix-font-Heading-M-variant': `normal`,
  '--wix-font-Heading-M-weight': `normal`,
  '--wix-font-Heading-M-size': `20px`,
  '--wix-font-Heading-M-line-height': `1.5em`,
  '--wix-font-Heading-M-family': `madefor-text-mediumbold, helveticaneuew01-45ligh,
  helveticaneuew02-45ligh, helveticaneuew10-45ligh, sans-serif, Noto Sans Hebrew`,
  '--wix-font-Heading-M-text-decoration': `none`,
  '--wix-font-Heading-S': `normal normal normal 16px/1.5em madefor-text-mediumbold,
  helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
  sans-serif`,
  '--wix-font-Heading-S-style': `normal`,
  '--wix-font-Heading-S-variant': `normal`,
  '--wix-font-Heading-S-weight': `normal`,
  '--wix-font-Heading-S-size': `16px`,
  '--wix-font-Heading-S-line-height': `1.5em`,
  '--wix-font-Heading-S-family': `madefor-text-mediumbold, helveticaneuew01-45ligh,
  helveticaneuew02-45ligh, helveticaneuew10-45ligh, sans-serif`,
  '--wix-font-Heading-S-text-decoration': `none`,
  '--wix-font-Body-L': `normal normal normal 20px/1.5em
  wfont_ebd955_e452130b593f4a27b692b74fd0437112,
      wf_e452130b593f4a27b692b74fd, orig_wix_madefor_text_regular, Noto Sans Hebrew`,
  '--wix-font-Body-L-style': `normal`,
  '--wix-font-Body-L-variant': `normal`,
  '--wix-font-Body-L-weight': `normal`,
  '--wix-font-Body-L-size': `20px`,
  '--wix-font-Body-L-line-height': `1.5em`,
  '--wix-font-Body-L-family': `wfont_ebd955_e452130b593f4a27b692b74fd0437112,
      wf_e452130b593f4a27b692b74fd, orig_wix_madefor_text_regular,Noto Sans Hebrew`,
  '--wix-font-Body-L-text-decoration': `none`,
  '--wix-font-Body-M': `normal normal normal 16px/1.5em
  wfont_ebd955_e452130b593f4a27b692b74fd0437112,
      wf_e452130b593f4a27b692b74fd, orig_wix_madefor_text_regular, Noto Sans Hebrew`,
  '--wix-font-Body-M-style': `normal`,
  '--wix-font-Body-M-variant': `normal`,
  '--wix-font-Body-M-weight': `normal`,
  '--wix-font-Body-M-size': `16px`,
  '--wix-font-Body-M-line-height': `1.5em`,
  '--wix-font-Body-M-family': `wfont_ebd955_e452130b593f4a27b692b74fd0437112,
      wf_e452130b593f4a27b692b74fd, orig_wix_madefor_text_regular, Noto Sans Hebrew`,
  '--wix-font-Body-M-text-decoration': `none`,
  '--wix-font-Body-S': `normal normal normal 14px/1.5em
  wfont_ebd955_e452130b593f4a27b692b74fd0437112,
      wf_e452130b593f4a27b692b74fd, orig_wix_madefor_text_regular,Noto Sans Hebrew`,
  '--wix-font-Body-S-style': `normal`,
  '--wix-font-Body-S-variant': `normal`,
  '--wix-font-Body-S-weight': `normal`,
  '--wix-font-Body-S-size': `14px`,
  '--wix-font-Body-S-line-height': `1.5em`,
  '--wix-font-Body-S-family': `wfont_ebd955_e452130b593f4a27b692b74fd0437112,
      wf_e452130b593f4a27b692b74fd, orig_wix_madefor_text_regular, Noto Sans Hebrew`,
  '--wix-font-Body-S-text-decoration': `none`,
  '--wix-font-Body-XS': `normal normal normal 12px/1.5em din-next-w01-light,
  din-next-w02-light, din-next-w10-light, sans-serif`,
  '--wix-font-Body-XS-style': `normal`,
  '--wix-font-Body-XS-variant': `normal`,
  '--wix-font-Body-XS-weight': `normal`,
  '--wix-font-Body-XS-size': `12px`,
  '--wix-font-Body-XS-line-height': `1.5em`,
  '--wix-font-Body-XS-family': `din-next-w01-light, din-next-w02-light,
  din-next-w10-light, sans-serif`,
  '--wix-font-Body-XS-text-decoration': `none`,
  '--wix-font-LIGHT': `normal normal normal 12px/1.5em HelveticaNeueW01-45Ligh`,
  '--wix-font-LIGHT-style': `normal`,
  '--wix-font-LIGHT-variant': `normal`,
  '--wix-font-LIGHT-weight': `normal`,
  '--wix-font-LIGHT-size': `12px`,
  '--wix-font-LIGHT-line-height': `1.5em`,
  '--wix-font-LIGHT-family': `HelveticaNeueW01-45Ligh`,
  '--wix-font-LIGHT-text-decoration': `none`,
  '--wix-font-MEDIUM': `normal normal normal 12px/1.5em HelveticaNeueW01-55Roma`,
  '--wix-font-MEDIUM-style': `normal`,
  '--wix-font-MEDIUM-variant': `normal`,
  '--wix-font-MEDIUM-weight': `normal`,
  '--wix-font-MEDIUM-size': `12px`,
  '--wix-font-MEDIUM-line-height': `1.5em`,
  '--wix-font-MEDIUM-family': `HelveticaNeueW01-55Roma`,
  '--wix-font-MEDIUM-text-decoration': `none`,
  '--wix-font-STRONG': `normal normal normal 12px/1.5em HelveticaNeueW01-65Medi`,
  '--wix-font-STRONG-style': `normal`,
  '--wix-font-STRONG-variant': `normal`,
  '--wix-font-STRONG-weight': `normal`,
  '--wix-font-STRONG-size': `12px`,
  '--wix-font-STRONG-line-height': `1.5em`,
  '--wix-font-STRONG-family': `HelveticaNeueW01-65Medi`,
  '--wix-font-STRONG-text-decoration': `none`,
};
