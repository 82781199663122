import { useBi } from '@wix/yoshi-flow-editor';
import { riseWixPluginLoadedSrc35Evid200 } from '@wix/bi-logger-rise-ai-data/v2';
import { useApplicationContext } from './useApplicationContext/useApplicationContext';
import { useRef } from 'react';
import {
  defaultBiMultiplier,
  sitePluginId,
  sitePluginName,
} from '../../consts';

export const useLoadedBiEvent = () => {
  const bi = useBi();
  const { userTotalStoreCreditNumber } = useApplicationContext();
  const isReported = useRef(false);

  const reportLoadedBiEvent = () => {
    if (!isReported.current) {
      isReported.current = true;
      bi.report(
        riseWixPluginLoadedSrc35Evid200({
          pluginId: sitePluginId,
          appName: sitePluginName,
          balance: userTotalStoreCreditNumber! * defaultBiMultiplier,
        }),
      );
    }
  };

  return { reportLoadedBiEvent };
};
